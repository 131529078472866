<template>
    
  <LiefengContent  @backClick="clickBar" isBack="true" :navList="navList" @clickBar="clickBar">
      <template #title>{{titleName}}</template>
      <template #toolsbarRight v-if="showBar">
          <Select  v-model="selectValue" style="width:200px" @on-change="changeSelect">
            <Option v-for="(item,index) in selectList" :value="item.value" :key="index">{{ item.label }}</Option>
        </Select>
      </template>
      <template #contentArea>
          <!--街道使用情况-->
          <Card style="margin:20px 0 ">
              <TitleColor :title='titleName'>
                  <template #titleRight>
                      {{$core.formatDate(new Date(),'yyyy-MM-dd '+'00:00:00更新')}}
                  </template>
              </TitleColor>
              <div class="stree-div">
                  <div class="left">
                      <VeRing :ringData="streeUsage.ring" :listData='streeUsage.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="streeUsage.tableData" :tableColumn="streeUsage.streeColumn"></VeTable>
                  </div>
              </div>
              <div class="bar-div" v-if="showBar">
                 <VeBar :showSelect="false" :data="streeUsage.barData"  :settings="settings"></VeBar>
             </div>
          </Card>
      </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from '../components/LiefengContent2.vue'
import TitleColor from '../components/TitleColor'
import VeRing from '../components/VeRing'
import VeTable from '../components/Table'
import VeBar from '../components/VeBar'
import {mixinRequest} from '../utils/mixinRequest'
export default {
    components:{LiefengContent,TitleColor,VeRing,VeTable,VeBar},
    mixins:[mixinRequest],
    data(){
        return{
            // 面包屑导航栏
            navList:[],
            // 当前页面标题
            titleName:'使用情况',
            // 存放用户信息
            portalInfo:{},

            // 绑定的搜索参数
            selectValue:'',
            // 绑定的选择框列表
            selectList:[],
            // 数据绑定
            streeUsage:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[],
                barData:[]
            },
            settings:{
                stack:{'社区':['示范社区数','试用社区数','社区总数']}
            },

            showBar:false
            
        }
    },
   async created(){
        this.portalInfo = this.$store.state.portal ? this.$store.state.portal : {}
        if(this.portalInfo && !this.$route.query.orgCode){
            if(this.portalInfo.orgLevel == 4){
                // 当前是街道，进来是社区
                this.titleName =  '社区使用情况'
                this.showBar = false
            }else if(this.portalInfo.orgLevel == 3){
                // 如果当前是区，进来是街道
                this.showBar = true
                this.titleName = this.portalInfo.orgName + '街道使用情况'
                if(this.portalInfo.orgCode && this.portalInfo.orgCode != '')
                this.selectList =  this.formatterData(await this.getSelectList(this.portalInfo.orgCode))
            }
            this.navList.push(...(JSON.parse(this.$route.query.router)),{
                name:this.titleName,
                router:this.$route.path
            })
            await this.getStree()
        }else if(this.portalInfo && this.$route.query.orgCode){
            this.showBar = false
            this.titleName = '社区使用情况'
            if(this.portalInfo.orgCode && this.portalInfo.orgCode != '')
            this.selectList =  this.formatterData(await this.getSelectList(this.portalInfo.orgCode))
            this.navList.push(...(JSON.parse(this.$route.query.router)),{
                name:this.titleName,
                router:this.$route.path
            })
            let data = await this.getStreeList(this.$route.query.orgCode)
            this.streeUsage.ring = {
                columns:['title','num'],
                rows:[
                        {title:'其他社区',num:data.other},
                        {title:'示范社区（覆盖社区数）',num:data.pilot},
                        // {title:'社区总数',num:data.total},
                        {title:'试用社区（覆盖社区数）',num:data.trial},
                    ]
                }
                this.streeUsage.list = {
                   rows:[
                        {title:'社区总数',num:data.total},
                        {title:'示范社区（覆盖社区数）',num:data.pilot},
                        {title:'试用社区（覆盖社区数）',num:data.trial},
                        {title:'其他社区',num:data.other},
                    ] 
                }
                   this.streeUsage.streeColumn = [
                       {
                            title: '社区名称',
                            key: 'orgName',
                            align: "center",
                        },
                       {
                            title: '使用情况',
                            key: 'orgOnlineType',
                            align: "center",
                        },
                       {
                            title: '上线时间',
                            key: 'gmtCreate',
                            align: "center",
                            render:(h,params) =>{
                                return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                                        new Date(params.row.gmtCreate),
                                        "yyyy-MM-dd"
                                      ):'')
                            }
                        },
                   ]
                    if(data.communityRoList && data.communityRoList.length > 0) this.streeUsage.tableData = data.communityRoList
                    else this.streeUsage.tableData = []
        }
        
        
    },
    methods:{
        // 搜索选择框change回调事件
       async changeSelect(val){
           this.showBar = false
            this.titleName = '社区使用情况'
            this.navList.push({
                name:this.titleName,
                router:this.$route.path
            })
            
             let data = await this.getStreeList(val)
                   this.streeUsage.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'其他社区',num:data.other},
                           {title:'示范社区（覆盖社区数）',num:data.pilot},
                        //    {title:'社区总数',num:data.total},
                           {title:'试用社区（覆盖社区数）',num:data.trial},
                       ]
                   }
                   this.streeUsage.list = {
                   rows:[
                        {title:'社区总数',num:data.total},
                        {title:'示范社区（覆盖社区数）',num:data.pilot},
                        {title:'试用社区（覆盖社区数）',num:data.trial},
                        {title:'其他社区',num:data.other},
                    ] 
                }
                   this.streeUsage.streeColumn = [
                       {
                            title: '社区名称',
                            key: 'orgName',
                            align: "center",
                        },
                       {
                            title: '使用情况',
                            key: 'orgOnlineType',
                            align: "center",
                        },
                       {
                            title: '上线时间',
                            key: 'gmtCreate',
                            align: "center",
                            render:(h,params) =>{
                                return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                                        new Date(params.row.gmtCreate),
                                        "yyyy-MM-dd"
                                      ):'')
                            }
                        },
                   ]
                    if(data.communityRoList && data.communityRoList.length > 0) this.streeUsage.tableData = data.communityRoList
                    else this.streeUsage.tableData = []
                   
        },

        searchBtn(){
            console.log();
        },
        async clickBar(item,index){
            this.navList.splice(this.navList.length - 1,1)
            this.titleName = this.navList[this.navList.length - 1].name
            this.showBar = true
            this.selectValue = ''
            await this.getStree()

        },

        // 格式化拿到的参数
        formatterData(data){
            let list = []
            if(data && data.length != 0){
                data.map(item =>{
                    list.push({
                        value:item.orgCode,
                        label:item.orgName
                    })
                })
            }
            return list
        },
        // 街道使用情况数据处理
       async getStree(){
             // 判断是否获取当了当前用户的信息
            if(this.portalInfo && this.portalInfo.orgLevel != ''){
                // 判断当前维度
                if(this.portalInfo.orgLevel == 3){
                    // 社区
                    let data = await this.getOrgStreeUsage(this.portalInfo.orgCode)
                   this.streeUsage.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'其他街道',num:data.other},
                           {title:'示范街道（覆盖街道数）',num:data.pilot},
                        //    {title:'街道总数',num:data.total},
                           {title:'试用街道（覆盖街道数）',num:data.trial},
                       ]
                   }
                   this.streeUsage.list = {
                    rows:[
                            {title:'街道总数',num:data.total},
                            {title:'示范街道（覆盖街道数）',num:data.pilot},
                            {title:'试用街道（覆盖街道数）',num:data.trial},
                            {title:'其他街道',num:data.other},
                        ] 
                    }
                   this.streeUsage.streeColumn = [
                       {
                            title: '街道名称',
                            key: 'streetName',
                            align: "center",
                            render:(h,params) => {
                                return h('div',[
                                h('a',{
                                    on:{
                                    'click':()=>{
                                        // 点击跳转
                                        this.changeSelect(params.row.streetCode)
                                    }
                                    }
                                },params.row.streetName)
                                ])
                            }
                        },
                       {
                            title: '示范社区数',
                            key: 'pilotCommunity',
                            align: "center",
                        },
                       {
                            title: '试用社区数',
                            key: 'trialCommunity',
                            align: "center",
                        },
                       {
                            title: '社区总数',
                            key: 'toTalCommunity',
                            align: "center",
                        },
                   ]
                   if(data.streetRoList && data.streetRoList.length > 0) this.streeUsage.tableData = data.streetRoList
                   else this.streeUsage.tableData = []
                   

                   if(data.streetRoList && data.streetRoList.length != 0){
                       this.streeUsage.barData = {
                            columns:['街道名称','示范社区数','试用社区数','社区总数'],
                            rows:[]
                        }
                       data.streetRoList.map(item =>{
                           this.streeUsage.barData.rows.push({
                               '街道名称':item.streetName,
                               '示范社区数':item.pilotCommunity,
                               '试用社区数': item.trialCommunity,
                               '社区总数':item.toTalCommunity
                           })
                       })
                   }
                }else if(this.portalInfo.orgLevel == 4){
                    // 街道
                   let data = await this.getStreeList(this.portalInfo.orgCode)
                   this.streeUsage.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'其他社区',num:data.other},
                           {title:'示范社区（覆盖社区数）',num:data.pilot},
                        //    {title:'社区总数',num:data.total},
                           {title:'试用社区（覆盖社区数）',num:data.trial},
                       ]
                   }
                   this.streeUsage.list = {
                    rows:[
                            {title:'社区总数',num:data.total},
                            {title:'示范社区（覆盖街社区）',num:data.pilot},
                            {title:'试用社区（覆盖街社区）',num:data.trial},
                            {title:'其他社区',num:data.other},
                        ] 
                    }
                   this.streeUsage.streeColumn = [
                       {
                            title: '社区名称',
                            key: 'orgName',
                            align: "center",
                        },
                       {
                            title: '使用情况',
                            key: 'orgOnlineType',
                            align: "center",
                        },
                       {
                            title: '上线时间',
                            key: 'gmtCreate',
                            align: "center",
                            render:(h,params) =>{
                                return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                                        new Date(params.row.gmtCreate),
                                        "yyyy-MM-dd"
                                      ):'')
                            }
                        },
                   ]
                   if(data.communityRoList && data.communityRoList.length > 0) this.streeUsage.tableData = data.communityRoList
                    else this.streeUsage.tableData = []
                   
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.stree-div{
    display: flex;
    margin-top: 20px;
    .left{
        width: 50%;
    }
    .right{
        margin-left: 20px;
        width: 50%;
        min-height: 200px;
        max-height: 500px;
    }
}
.bar-div{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border-top: 1px dashed #ccc;
    padding-top: 10px;
    // &:nth-last-child(1){
    //     border-bottom: none;   
    // }
}
</style>